import React, { Component } from "react";
import Slider from "react-slick";

export default class SliderLogos extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div style={{ maxWidth: "60vh" }}>
        <Slider {...settings}>
          <div>
            <img src="./react.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./nextjs.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./reactnative.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./jshtmlcss.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./nodejs.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./express.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./tree.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./framer.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./electron.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./mui.png" style={{ height: "5vh" }} />
          </div>
          <div>
            <img src="./bootstrap.png" style={{ height: "5vh" }} />
          </div>
        </Slider>
      </div>
    );
  }
}
