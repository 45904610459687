import React from "react";
import SliderLogos from "../SliderLogos";
import styles from "./MyProject.module.css";
import { FaGooglePlay, FaAppStore } from "react-icons/fa";
import "./buttonStyles.css";

function MyProject() {
  return (
    <div className="container">
      <center>
        <h5
          style={{
            marginTop: "14vh",
            // paddingBottom: "1rem",
            padding: "2rem",
            fontFamily: "Poppins",
            fontWeight: "bolder",
            color: "white",
            fontSize: "6vh",
            textAlign: "left",
            overflow: "hidden",
          }}
        >
          {/* <SliderLogos /> */}
          <span className={styles.hfive}> In code we trust</span>🔮
        </h5>

        <br />
        <h4
          style={{
            fontSize: "20px",
            fontFamily: "Poppins",
            fontWeight: "normal",
            color: "white",
            textAlign: "left",
            overflow: "hidden",
            padding: "2rem",
            marginBottom: "1rem",
          }}
        >
          List of projects done by us. We strive for the best.
        </h4>
        <section
          style={{
            marginTop: "1rem",
            width: "100%",
            //     height: "50vh",
            background: "rgb(19,30,37)",
            //     margin: "10vh",
            borderRadius: "4.3rem",
            background:
              "linear-gradient(0deg, rgba(9,22,31,1) 0%, rgba(19,30,37,1) 100%)",

            zIndex: "1",
          }}
        >
          <div className="row" style={{ marginTop: "5rem" }}>
            <div className="col-lg-6">
              <img
                // className="img-fluid"
                src="./icon.png"
                className={styles.imgfluid}
                style={{
                  // filter: "saturate(60%) brightness(80%)",
                  height: "13vh",
                  //   width: "40vh",
                  // padding: "4rem",
                  borderRadius: "22rem",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.panjere.app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="custom-btn btn-google-play"
                >
                  <FaGooglePlay className="icon" /> Download on Google Play
                </a>
                <a
                  href="https://apps.apple.com/tr/app/panjere/id1661181681"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="custom-btn btn-app-store"
                >
                  <FaAppStore className="icon" /> Download on App Store
                </a>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{
                color: "white",
                paddingTop: "2rem",
                paddingBottom: "4rem",
              }}
            >
              <h3 className={styles.howcan}> Project 01</h3>
              <h6
                style={{
                  fontFamily: "Poppins",
                  color: "#fff",
                  fontWeight: "bolder",
                  overflow: "hidden",
                  padding: "0.5rem",
                }}
              >
                Next js - React Native - node js - express - mongodb
              </h6>
              <h6
                style={{
                  fontFamily: "Poppins",
                  overflow: "hidden",

                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                android - ios - pwa - admin panel
              </h6>
              <h3
                style={{
                  marginTop: "4rem",
                  marginBottom: "2rem",
                  overflow: "hidden",
                }}
              >
                PANJERE
              </h3>

              {/* <button type="button" class="btn btn-warning">
                <span style={{ fontFamily: "Poppins", fontWeight: "bolder" }}>
                  VISIT THE WEBSITE
                </span>
              </button> */}
              <br />
              <a
                style={{ textDecoration: "none", cursor: "pointer" }}
                className={styles.aaa}
                href="https://panjere.app"
                target="_blank"
              >
                <span>VISIT</span>
                <div class={styles.liquid}></div>
              </a>
            </div>
          </div>
        </section>
      </center>
    </div>
  );
}

export default MyProject;
