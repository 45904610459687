import AfterHeader from "./Components/AfterHeader/AfterHeader";
import CardPrice from "./Components/CardPrice/CardPrice";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import MyProject from "./Components/MyProject/MyProject";

function HomeScreen() {
  return (
    <div>
      <Header />
      <AfterHeader />
      <MyProject />

      <CardPrice />
      <Footer />
    </div>
  );
}

export default HomeScreen;
